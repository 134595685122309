div#scrollable-container {
    overflow: auto;
    height: 280px;
}
div#bar-chart-wrap canvas {
    margin: auto;
}
.btn-logout p {
    display: inline-block;
    margin-left: 30px!important;
}
#dashboard .left{
    background-color: #00469e;
    text-align: center;
}
#dashboard .left .nav-wrap{
    height: 100vh;
}
#dashboard .left .gpa_logo{
    width: 80px;
    height: 80px;
    margin-top: 15px;
}
#dashboard .left .account_logo{
    width: 20px;
    height: 20px;
    margin-top: 15px;
}
#dashboard .left p{
    color: #fff;
    font-size: 13px;
    padding-top: 10px;
    margin-bottom: 0px;
    font-weight: 500;
}
#dashboard .left hr{
    width: 90px;
    background-color: #fff;
    margin-top: 35px;
    margin-bottom: 20px;
}

#dashboard .left .links{
    margin-bottom: 35px;
}
#dashboard .left .links p{
    padding-bottom: 10px;
}
#dashboard .right h5{
    font-weight: 600;
}
#dashboard .right .main{
    margin: 45px 30px;
}
#dashboard .right .main hr{
    border-top: 2px solid #cccccc;
}
#dashboard .right .main .survey_wrap{
    padding-bottom: 30px;
}
#dashboard .right .main .survey_wrap .featured_image{
    display: inline-block;
}
#dashboard .right .main .survey_wrap .content{
    display: inline-block;
    vertical-align: middle;
    max-width: 640px;
}
#dashboard .right .main .survey_wrap .content a{
    font-weight: 600;
    font-size: 12px;
}
#dashboard #footer-main{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
#dashboard #footer-main{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
#dashboard .main .customer-feedback button{
    background-color: #df0018!important;
    border-color: #ef828e!important;
    width: 185px!important;
    padding: 10px;
    float: right;
}

#dashboard .main .customer-feedback input, #dashboard .main .customer-feedback select, #dashboard .main .customer-feedback textarea{
    border-radius: 5px;
    border: 2px solid #cccccc;
}

#dashboard .main .edit-form{    
    max-width: 750px;
}

#dashboard .main .edit-form input{    
    border: 2px solid #cccccc;
}

#dashboard .main .edit-form select{    
    border: 2px solid #cccccc;
}

#dashboard .main .edit-form label{    
    font-weight: 500;
    color: #333333;
}

#dashboard .main .edit-form h5 {
    font-size: 18px;
    color: #333333;
}

#dashboard .main .edit-form .change-pass{
    padding: 0px 25px 50px 0px;
}
#dashboard .main .edit-form .change-pass a{
    font-weight: 500;
}
#dashboard .main .edit-form .change-pass .float-left h5{
    margin-top: 15px; 
}
#dashboard .main .edit-form .change-pass .float-right{
    margin-top: 15px;
    font-size: 15px;
}
#dashboard .main .edit-form .paperless-billing{
    padding: 10px 25px 30px 0px;
    font-size: 15px;
}
#dashboard .main .edit-form .paperless-billing h5{
    margin: 0px;
}

#dashboard .main #form-actions{
    padding: 20px 40px;
}
#dashboard .main #form-actions #save button{
    background-color: #df0018!important;
    border-color: #ef828e!important;
    width: 185px!important;
    padding: 10px;
}
#dashboard .main #form-actions #save button:hover{
    color: #df0018;
    background-color: white!important;
    border-color: #df0018!important
}
#dashboard .main #form-actions #cancel{
    padding: 10px 0;
    text-align: right;
}
.btn-logout{
    float: right;
}
.btn-logout p{
    margin: 0px;
    color: #ababab;
    cursor: pointer;
}
#dashboard .main .account-overview{
    margin-left: 0px;
    margin: auto;
}
#dashboard .main .account-overview .bill-summary .header{
    border: 1px solid #00469e;
    background-color: #00469e;
    color: white;
    padding: 10px 20px;
}
#dashboard .main .account-overview p{
    font-weight: 500;
    
}

#dashboard .main .account-overview .bill-summary .header p{
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    margin-right: 10px;
}
#dashboard .main .account-overview .bill-summary .header select{
    display: inline-block;
    width: 430px;
}

#dashboard .main .account-overview .bill-summary .border{
    border: 1px solid;
    border-top: 0px;
    padding: 10px;
}

#dashboard .main .account-overview .bill-summary .border-top-0{
    padding: 24px;
}

#dashboard .main .account-overview .bill-summary .border p{
    margin: auto;
}
#dashboard .main .account-overview .bill-summary .pay-now{
    padding: 40px;
}
#dashboard .main .account-overview .bill-summary .pay-now button{
    background-color: #df0018;
    border-color: #df0018;
    height: 42px;
    width: 100%;
}    
#dashboard .main .account-overview .bill-summary .pay-now button:hover{
    color: #df0018;
    background-color: white!important;
    border-color: #df0018!important;
} 
#dashboard .main #info-wrap{
    background-color: #f8f8f9;
    /*position: absolute;
    bottom: 65px;
    left: 15px;
    right: 0;*/
    padding: 30px 30px; 
}
table#account-numbers th , table#account-numbers td {
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
}
.dropdown-toggle::after {
    display: inline-block;
    margin-left: 55px!important;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
table#account-numbers td .due-date {
    width: 95px;
}
table#account-numbers tbody tr td div {
    margin: auto;
}
.account-summary .sub-total {
    text-align: right;
}      
.account-summary .nav-tabs a {
    color: #00459E!important;
    font-size: 14px;
    font-weight: 500;
}
table#account-numbers .actions-dropdown button{
    border-radius: 0px;
    height: 33px;
    background-color: white;
    color: black;
    font-size: 14px;
    border-color: #dee2e6;
    width: 210px;
}
table#account-numbers .amount-to-pay input {
    width: 140px;
}
#dashboard .main #info-wrap .account-info .header label{
    display: inline-block;
    margin-right: 60px;
}

#dashboard .main #info-wrap .account-info .header a{
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    margin-right: 60px;
}

#dashboard .main #info-wrap .account-info .body p{
    margin: 0px;
}
#dashboard .main #info-wrap .contact-info .body p{
    margin: 0px;
}

#dashboard .main .my-bills-info p{
    margin: 0px;
}

#dashboard .main .my-bills-table .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 1px;
    text-align: center;
    background-color: #00469e;
    color: white;
}
#dashboard .main .my-bills-table .table-bordered th, .table-bordered td {
    border: 1px solid #dee2e6;
    text-align: center;
}
#bar-chart-wrap {
    margin: 20px 25px;
}

#bar-chart-wrap .top label, #bar-chart-wrap .top p, #bar-chart-wrap .top select{
    display:inline-block;
}

#bar-chart-wrap .top p{
    font-size: 14px;
    font-weight: 600;
    padding-right: 10px;
}
#bar-chart-wrap .top select{
    font-size: 14px;
}
#bar-chart-wrap .top{
    padding: 0px 0px 15px;
}

#bar-chart-wrap .bottom .graph-label{
    margin: auto;
    text-align: center;
    padding-left: 45px;
}

#bar-chart-wrap .bottom .col-lg-3{
    margin: auto;
}

/* #dashboard .main .chartjs-size-monitor canvas{
    display: block;
    width: 1050px;
    height: 350px;
} */
#dashboard .nav_mobile{
    padding: 5px 30px;
}    
#dashboard .nav_mobile a{
    font-weight: 600
} 
#dashboard .nav_mobile button{
    float: right;
    margin-top: 10px;
}
#dashboard .nav_mobile .navbar-brand .gpa_logo{
    height: auto;
    width: 215px;
}
div#responsive-navbar-nav {
    padding: 25px;
}
#loading{
    position: fixed;
    top: 45%;
    left: 55%;
}
#loading .spinner-border {
    display: inline-block;
    width: 50px;
    height: 50px;
    vertical-align: text-bottom;
    border: 7px solid #00469e;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
.mr-auto.navbar-nav a{
    color: white;
}
#dashboard .main .my-bills-table .next, #dashboard .main .my-bills-table .prev{
    margin: 0px 10px;
    cursor: pointer;
    color: #666666;
}
#dashboard .main .account-overview .bill-summary .header input{
    width: 430px;
    padding: 1px 5px;
    pointer-events: none;
    border-radius: 5px;
}
@media (max-width: 480px){
    #dashboard .main .edit-form .paperless-billing {
        padding: 10px 25px 10px 0px;
        font-size: 15px;
    }
    #dashboard .main .edit-form .paperless-billing .float-left {
        float: none!important;
    }
    #dashboard .main .edit-form .paperless-billing .float-right {
        float: none!important;
        margin-top: 10px;
    }
}
@media (max-width: 360px){
    #dashboard #footer-main .float-left p{
        font-size: 3.5vw;
    }
    #dashboard .right .main .survey_wrap{
        text-align: center;
    }
    
}
@media (max-width: 420px){
    #dashboard .main #info-wrap{
        bottom: -40px!important; 
    }
    #dashboard #footer-main {
        bottom: -135px!important;
    }
    #dashboard .main #info-wrap .account-info .header a{
        margin-bottom: 10px
    }
    #dashboard .right .main #welcome-wrap h3 {
        font-size: 6.5vw;
    }
}
@media (min-width: 421px) and (max-width: 501px){
    #dashboard .main #info-wrap{
        bottom: -10px!important;
    }
    #dashboard #footer-main{
        bottom: -73px!important;
    }
}
@media (max-width: 570px){
    #dashboard .main .account-overview .bill-summary .header{
        text-align: center;
    }
    #dashboard .main .account-overview .bill-summary .header input{
        width: 100% !important
    }
}
@media (min-width:502px) and (max-width: 674px){
    #dashboard #footer-main{
        bottom: -75px!important;
    }
}
@media (min-width:421px) and (max-width: 674px){
    #dashboard .main #info-wrap {
        bottom: 22px!important;
    }
}
@media (max-width: 674px){
    #dashboard #footer-main .float-left{
        float: none!important;
        padding-left: 0!important;
    }
    #dashboard #footer-main .float-right{
        float: none!important;
        padding-right: 0px!important;
    }
    div#absolute-holder {
        margin: 385px;
    }
}
@media (min-width: 992px){
    #dashboard .nav_mobile{
        display: none
    }
    #dashboard .left{
        display: block;
    }
    .btn-logout {
        display: block;
    }
    #dashboard .right .main{
        margin: 45px 70px;
    }
    div#absolute-holder {
        margin: 310px;
    }
    #dashboard .main #form-actions #cancel {
        text-align: right;
    }
    #survey-list .required-field, #survey-list .not-required-field{
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 315px;
    }
}
.account-summary .table-overflow{
    height: 340px;
    overflow-y: scroll;
    border: 1px solid #dee2e6;
    overflow-x: hidden
}
.account-summary .account-summary-label{
    background-color: #00469e;
    padding: 15px 25px;
}
.account-summary .account-summary-label label{
    margin: 0;
}
@media (max-width: 991px){
    .account-summary .table-overflow {
        height: 340px;
        overflow-y: scroll;
        border: 1px solid #dee2e6;
        overflow-x: scroll!important;
    }
    div#absolute-holder {
        margin: 375px;
    }
    #dashboard .left{
        display: none;
    }
    .btn-logout {
        display: none;
    }
    #dashboard .nav_mobile{
        display: block;
    }
    #bar-chart-wrap .bottom{
        text-align: center;
    }
    #bar-chart-wrap .bottom .graph-label{
        padding: 20px 0px 20px 0px!important;
    }
    #bar-chart-wrap .bottom .graph-label label{
        transform: rotate(0deg);
    }
    #dashboard .main #info-wrap{
        bottom: 15px;
    }
    #dashboard #footer-main{
        bottom: -50px;
    }
    #loading{
        position: fixed;
        top: 45%;
        left: 45%;
    }
    #dashboard .main .account-overview .bill-summary .header select{
        width: 100%!important;
        text-align: center;
    }
    #bar-chart-wrap .scroll-hidden-mobile {
        width: 100%;
        padding: 0px;
        margin: 0px 30px 0px 30px;
        overflow-y: hidden;
    }
    #bar-chart-wrap #bar-scroll-mobile{
        overflow-y: scroll;
        width: 650px;
        margin: auto;
    }
    #bar-chart-wrap .top {
        padding: 0px 0px 0px;
    }
    
    #dashboard .main #form-actions #cancel,#dashboard .main #form-actions #save {
        text-align: center;
    }
    #dashboard .main .edit-form {
        max-width: 750px;
        margin: auto;
    }
    #survey-list select, #survey-list textarea{
        margin: 10px 0px
    }
}

#survey-list .thank-you-msg h5{
    font-weight: 400!important;
    font-style: italic;
}
#survey-list button{
    background-color: #df0018!important;
    border-color: #ef828e!important;
    width: 150px!important;
    padding: 10px; 
    float: right;
}
#survey-list a{
    margin: 0;
    float: right;
    width: 150px!important;
    height: 43px;
    text-align: center;
    padding: 10px;
}
#survey-list select, #survey-list textarea{
    margin-right: 15px;
    border-radius: 5px;
    border: 2px solid #cccccc;
}
#survey-list .btn-wrap {
    margin: 20px 0px;
}

#survey-list .thank-you-msg {
    margin: 20px 0px;
}

/* new CSS */
.consumption-detail p{
    margin: 0px
}
.consumption-detail .value p {
    font-size: 18px;
    font-weight: 600;
}
.consumption-detail {
    text-align: center;
    background-color: #eeeef1;
    margin: 5px 0px;
    padding: 10px;
}
#bar-chart-modal {
    max-width: 1093px;
    margin: 25px auto;
}
#bar-chart-modal .no-data {
    text-align: center;
    padding: 20px;
}
#bar-chart-modal .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
    padding: 1rem 1rem 0;
    border-bottom: 0;
}
#bar-chart-modal .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 15px;
    outline: 0;
}
.mav-pay-btn{
    background-color: #08c484!important;
    border-color: #08c484!important;
    font-size: 14px!important;
    width: 100px;
}
table#account-numbers .highlighted{
    background-color: #e6f2f9;
    transition: 0.3s;
}
div#welcome-wrap {
    margin-bottom: 15px
}
#my-bills-modal .my-bills-title {
    background-color: #00469e;
    padding: 15px 30px;
}
#my-bills-modal .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
    padding: 1rem 1rem 0;
    border-bottom: 0;
}
#my-bills-modal .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem 1.5rem;
}
a#uncontrolled-tab-example-tab-postpaid, a#uncontrolled-tab-example-tab-prepaid {
    font-size: 14px;
    font-weight: 500;
    vertical-align: middle;
    padding-top: 10px;
    background-color: #dee2e6;
}
.sub-total {
    padding: 0px 20px;
}
.sub-total button:hover {
    color: #08c484;
    background-color: white;
    border-color: #08c484;
}
div#pay-now-modal{
    max-width: 1200px;
}
.sub-total button{
    background-color: #08c484;
    border-color: #08c484;
    height: 42px;
    width: 150px;
}
section#review-order p {
    margin: 0;
}
section#review-order .section-title{
    padding-bottom: 15px;
}
section#review-order .bottom-hr{
    padding-bottom: 15px;
}
div#pay-now-modal .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-bottom: 0px;
    border-bottom: 0px;
}
section#credit-card-info .section-title {
    padding-bottom: 20px;
}
section#credit-card-info input[type=text] {
    height: 35px;
    border-radius: 0;
}
section#credit-card-info label {
    font-size: 14px;
}
section#credit-card-info button[type=submit]{
    background-color: #08c484;
    border-color: #08c484;
    height: 42px;
    width: 190px;
}
section#payment-options {
    margin-top: 15px;
}
section#credit-card-info {
    margin-top: 30px;
}
.container-check input:checked + .checkmark {
    background-color: #2196F3;
    transition: 0.3s;
}
.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075);
    transition: 0.3s;
}
div#popover-contained {
    z-index: 1;
}
section#payment-content h3, section#payment-content h5 {
    font-weight: 700;
}

section#payment-content .bordered {
    border: 1px solid #ebebeb;
    border-radius: 5px;
    max-width: 680px;
    padding: 35px;
    margin: auto;
}
section#payment-content .left-auto{
    width: 225px;
    margin: auto;
}

section#payment-content .header-left{
    border-bottom: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    padding: 15px
}

section#payment-content .header-right{
    border-bottom: 1px solid #ebebeb;
    padding: 15px
}
section#payment-content .header-left label, section#payment-content .header-right label {
    margin: 0;
}
section#payment-content label{
    display: block;
}

section#payment-content .content-left {
    border-right: 1px solid #ebebeb;
}
section#payment-content .content-left, section#payment-content .content-right{
    padding: 40px;
}

section#payment-content .btn-left{
    border-color: #adadad;
    color: #666666;
    padding: 15px 55px;
    min-width: 256.14px;
}
section#payment-content .btn-right{
    border-color: #08c484;
    background-color: #08c484;
    color: #fff;
    padding: 15px 55px;
}
#payment-history-modal {
    max-width: 500px;
    margin: 1.75rem auto;
    font-size: 14px;
}
div#my-bills-modal {
    font-size: 14px;
}
#payment-history-modal .my-bills-title {
    background-color: #00469e;
    padding: 15px 25px;
}
#payment-history-modal .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-bottom: 0px;
    border-bottom: 0px;
}

table#account-numbers thead tr th{
    background-color: #e3e5e7;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 12px;
}

#popup-message .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 0px;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-bottom: 0;
}
#popup-message .modal-content {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    outline: 0;
}

div#popup-message .modal-header img {
    width: 25px;
    margin-bottom: 5px;
}

div#popup-message .modal-body label {
    font-size: 14px;
    font-weight: 500;
}

#popup-custommessage .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 0px;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-bottom: 0;
}
#popup-custommessage .modal-content {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    outline: 0;
}
div#popup-custommessage .modal-header img {
    width: 25px;
    margin-bottom: 5px;
}
div#popup-custommessage .modal-body label {
    font-size: 14px;
    font-weight: 500;
}

.btn-logout p:hover {
    color: #00469e;
}
#paperless-billing-modal div#cancel, #paperless-billing-modal div#save {
    display: inline-block;
}

#paperless-billing-modal div#cancel {
    vertical-align: middle;
    width: 120px;
    height: 42px;
    padding: 5px;
}

#paperless-billing-modal label.on:before {
    content: 'ON';
    margin-left: 15px;
    color: white;
}

#paperless-billing-modal  label.off:after {
    content: 'OFF';
    margin-left: 37px;
    color: white;
}

#paperless-billing-modal {
    max-width: 550px!important;
}

#paperless-billing-modal .modal-header{
    border-bottom: 0;
}
#paperless-billing-modal .modal-body{
    padding: 0px 40px!important;
    padding-bottom: 30px!important;
}
#paperless-billing-modal #form-actions{
    text-align: center;
    margin-top: 25px;
    margin-bottom: 15px;
}
#paperless-billing-modal #save button{
    background-color: #08c484;
    border-color: #08c484;
    height: 42px;
    width: 120px;
}
#paperless-billing-modal #close-result{
    border: 1px solid #c2c1c1;
    width: 100px;
    height: 42px;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 40px;
}
#paperless-billing-modal .account-info{
    margin-top: 32px;
    margin-right: 0px;
}
#paperless-billing-modal #form-actions{
    text-align: center;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 15px;
    max-width: 270px;
}
@media(max-width: 575px){
    #paperless-billing-modal .account-info {
        text-align: center;
    }
    #paperless-billing-modal .account-info p{
        margin: 0px;
    }
    #paperless-billing-modal .toggle-switch input{
        display: block;
    }
    #paperless-billing-modal .toggle-switch label{
        margin: 10px auto;
    }
}
.react-switch-label {
    width: 80px!important;
}
div#receipt {
    background-color: white;
    max-width: 720px;
    margin: auto;
}