@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
html,body{
    overflow-x:hidden;
    background-color: #fff;
}
html *
{
    font-family: Montserrat;
}
p{
    font-size: 14px;
}
li{
    font-size: 14px;
}
label{
    font-size: 14px;
}
select{
    height: 30px;
}
.regular-font-size{
    font-size: 14px!important;
}
.big-font-size{
    font-size: 16px!important;
}
.primary-back-color{
    background-color: #00459e;
}
.secondary-back-color{
    background-color: #df0018!important;
}

.primary-font-color{
    color: #00459e!important;
}
.secondary-font-color{
    color: #df0018!important;
}
.light-font-color{
    color: white;
}
.dark-font-color{
    color: #333333;
}
.font-extra-bold{
    font-weight: 800;
}
.font-bold{
    font-weight: 600!important;
}
.no-line-height{
    line-height: 0;
}
.custom-line-height{
    line-height: 2.5;
}
.was-validated .form-control:invalid, .form-control.is-invalid{
    border-color: #dc3545!important;
}
.was-validated .form-control:invalid, .form-control.is-invalid {
    background-image: none!important;
}
.was-validated .form-control:valid, .form-control.is-valid {
    background-image: none!important;
}
.invalid{
    border-color: #dc3545!important;
}
.valid{
    border-color: #cccccc!important;
}
.invalid_msg{
    display: block!important;
}
.invalid_msg_small{
    font-size: 70%!important;
    display: block!important;
}
.invalid-feedback{
    display: none;
}
input[type=password] {
    font-weight: 900;
}
.alert {
    padding: 20px 35px!important;
    text-align: center;
    margin-bottom: 0!important;
    border: 0!important;
    border-radius: 0!important;
}
.alert-success {
    background-color: #4caf50!important;
}
.alert-danger {
    background-color: #ef5350!important;
}
.alert p{
    margin: 0;
    font-size: 14px;
    color: #ffffff;
    font-weight: 800;
}
.pa-btn-secondary {
    background-color: transparent!important;
    border: 1px solid #6D6D6D!important;
    border-radius: 0!important;
    padding: 10px 50px!important;
    font-weight: 500!important;
    width: 219px;
}
.btn.pa-btn-primary{
    background-color: #07C484!important;
    border: 1px solid #07C484!important;
    color: #fff!important;
    border-radius: 0!important;
    padding: 10px 50px;
    font-weight: 500!important;
    min-width: 227px;
}
#payment-arrangement-thank-you {
    height: 100vh!important;
}
#payment-arrangement-thank-you, #payment-arrangement-info, #payment-arrangement-use-deposit, #payment-arrangement-pay-today, #payment-arrangement-apply, #payment-arrangement-print-sign-send {
    background-color: #f3f6f7;
    height: 100%;
    font-size: 14px;
}
#payment-arrangement-print-sign-send ol{
    padding-left: 15px;
}
table#payment-arrangement-step-table {
    border: 3px solid #00459e;
}
table#payment-arrangement-step-table thead {
    background-color: #01449D;
    color: #fff;
}
table#payment-arrangement-step-table .pa-btn-primary {
    width: 150px;
    padding: 5px!important;
    font-size: 14px;
}
table#payment-arrangement-step-table td {
    vertical-align: middle;
}
table#payment-arrangement-step-table label.react-switch-label {
    /* margin: -17px; */
    margin: auto;
    margin-top: -25px;
}
#payment-arrangement-info .dark-table-data {
    background-color: #F5F4F9;
    vertical-align: middle;
}
#payment-arrangement-info .light-table-data{
    background-color: #fff;
    text-align: right;
}
#payment-arrangement-info table{
    border: 3px solid #00459e;
    border-collapse: unset;
    font-size: 16px;
    font-weight: 600;
}
#payment-arrangement-info .table-header{
    background-color:#00459e;
    height: 60px;
    color: #fff
}
#payment-arrangement-info .table-header h4{
    padding: 17px;
}
#payment-arrangement-stepper .step-wrap {
    position: relative;
    height: 145px;
    width: 183px;
    display: inline-block;
}
#payment-arrangement-stepper .step-wrap.last-step {
    width: 140px;
}
#payment-arrangement-stepper .step-wrap span.highlighted-circle {
    font-size: 350px;
    position: absolute;
    top: -190px;
    left: -8px;
    color: #01449D;
}
#payment-arrangement-stepper .step-wrap span.arrow {
    position: absolute;
    font-size: 130px;
    left: 150px;
    color: #01449D;
}
#payment-arrangement-stepper span.plain-circle {
    font-size: 160px;
    position: absolute;
    top: -22px;
    left: 10px;
    color: #01449D;
    min-width: 174.14px;
}
#payment-arrangement-stepper p.step-label {
    position: absolute;
    font-weight: 600;
    color: #01449D;
}
#payment-arrangement-stepper p.step-label.active{
    color: #fff;
}
td#pa-file-upload-field input[type='file'] {
    opacity: 0;
    position: absolute;
    z-index: -1;
}
td#pa-file-upload-field label {
    border: 1px solid #dee2e6;
    background-color: #dee2e6;
    padding: 5px 25px;
    margin: 0;
    cursor: pointer;
}
td#pa-file-upload-field input[type='text'] {
    padding: 4px;
    margin-right: 10px;
}
#create_account .signup-form{
    max-width: 900px;
    margin: auto;
    padding: 35px;
    border: 2px solid #cccccc;
    border-radius: 10px;
    margin-top: 35px;
    margin-bottom: 70px;
}
#create_account .signup-form input {
    border: 2px solid #cccccc;
}
#create_account .signup-form label {
    font-size: 14px;
    font-weight: 500;
}
#create_account .signup-form select {
    border: 2px solid #cccccc;
}

/* #create_account .signup-form label {
    float: left;
    margin-right: 10px;
}
#create_account .signup-form input {
    float: right;
}
#create_account .signup-form #feedback {
    margin-top: 1px;
    font-size: 80%;
    color: #dc3545;
    margin-left: 10px;
    width: 100%!important;
    font-weight: 500;
} */
#create_account .signup-form button {
    background-color: #df0018!important;
    border-color: #ef828e!important;
    width: 185px!important;
    padding: 10px;

}

.btn-forgot-password{
    cursor: pointer;
}
.btn-forgot-password:hover{
    text-decoration: underline;
}    
#reset_password #main-form{
    margin: 100px 0px 200px;    
}
#reset_password #main-form .form-row{
    width: 352px;
    margin: auto;    
}
#reset_password #main-form .form-row input{
    width: 100%;
    height: 42px;
    border-radius: 5px;
    border: 2px solid #cccccc;   
}
#reset_password #main-form .form-row label{
    margin-top: 0.5rem;
    margin-bottom: 3px;
}
#reset_password #main-form .form-row button{
    margin-left: auto;
    background-color: #df0018!important;
    border-color: #ef828e!important;
    border-radius: 10px;
    width: 166px!important;
    height: 42px;
}

#reset_password #main-form .form-row button:hover{
    color: #df0018;
    background-color: white!important;
    border-color: #df0018!important;
}

#account_number .custom-margin{
    margin: 45px 0px 40px;
}
#account_number .input-full-width{
    padding: 5px 10px;
    width: 100%;
}
#create_account .custom-margin{
    margin: 40px 0px 100px;
}
#create_account #cancel{
    padding: 10px 0;
    text-align: right;
}
#create_acount .gpa-form label{
    line-height: 0;
}
#header-main .nav-ian{
    height: 80px;
    margin-bottom: 70px;
}
#header-main .nav-ian .nav-brand img{
    height: auto;
    width: 100%;
}
#footer-main .fa-ul{
    padding-left: 15px;
    margin: 5px;
}

#footer-top{
    padding: 70px 25px;
}
#footer-bottom .footer-bottom-text{
    float: left;
    padding: 28px 13px;
}
#footer-bottom .footer-bottom-icons{
    float: right;
    padding: 15px 13px;
}
#footer-bottom{
    align-items: center;
}
#footer-bottom .footer-bottom-content
{
    width: 100%;
}
.gpa-form .btn-primary{
    background-color: #df0018!important;
    border-color: #ef828e!important;
    width: 150px!important;
}
.gpa-form .btn-primary:hover {
    color: #df0018;
    background-color: #0069d9;
    border-color: #df0018!important;
    background-color: white!important;
}
input#terms-condi {
    margin-right: 5px;
    position: relative;
    top: 0px;
    display: inline-block;
    width: auto;
}
.custom-label-terms {
    position: relative;
    top: -13px;
}
.custom-error-terms {
    margin-top: -17px!important;
}
#sign-up-form input[type="checkbox"]:focus{
    border-color: #fff!important;
    box-shadow: none!important;
}
#login .login-banner{
    background-image: url(/images/NewGPWABanner.jpg);
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
}
#login .login-banner .custom-column-height{
    height: 100vh;
}
#login .login-banner .padding-right{
    top: 50%;
    position: relative;
    transform: translateY(-50%);
    padding: 0px 50px;
}
#login .center-element {
    top: 49%;
    position: relative;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
#login .login-form{
    padding: 35px 110px 5px;
    text-align: center;
}
#login .login-form button{
    background-color: #4caf50;
    border-color: #4caf50;
    height: 42px;
    width: 100%;
}

#login .login-form button:hover{
    color: #4caf50;
    background-color: white!important;
    border-color: #4caf50!important;
}

#login .login-form label{
    float: left;
}

#login .login-form input{
    padding: 10px;
    height: 42px;
    width: 100%;
}
#login .remember-me{
    padding: 0px 20px 0px;
}
#login .forgot{
    text-align: center;
}
#login #login_actions button {
    background-color: #df0018;
    border-color: #df0018;
    height: 42px;
    width: 100%;
}
#login #login_actions button:hover {
    color: #df0018;
    background-color: white!important;
    border-color: #df0018!important;
}
#login .remember-me {
    padding: 0px 0px 0px 35px;
}
.forgot-modal .modal-content{
    padding: 15px;
    border-radius: 20px;
}
.forgot-modal .modal-content .modal-header{
    border-bottom: 0px; 
}
.forgot-modal .modal-content .modal-footer{
    border-top: 0px; 
}
.forgot-modal .modal-content .modal-header button{
    font-size: 35px;
    font-weight: 400;
    padding: 10px 15px;
} 
.forgot-modal .modal-content .modal-body input{
    width: 100%;
    border-radius: 5px;
    border: 1px solid #cccccc;
    padding: 10px 13px;
}    
.forgot-modal .modal-content .modal-body {
    padding: 0px 30px;
}  
.hint-modal .modal-content .modal-body {
    padding: 0px;
} 
.forgot-modal .modal-content .modal-body p {
    font-size: 13px;
}  
.forgot-modal .modal-content .modal-title h5 {
    font-size: 18px!important;
}  
.forgot-modal .modal-content .modal-footer button {
    background-color: #df0018;
    border-color: #df0018;
    border-radius: 24px;
    padding: 10px;
    width: 140px;
    margin-left: 15px;
} 
.forgot-modal .modal-content .modal-footer button:hover {
    color: #df0018;
    background-color: white!important;
    border-color: #df0018!important;
} 
.fade .modal .show{
    padding-right: 0px!important;
}
@media (min-width: 530px){
    #create_account .signup-form select {
        border: 2px solid #cccccc;
    }
    
    #create_account .signup-form label {
        float: left;
        margin-right: 10px;
    }
    #create_account .signup-form input {
        float: right;
    }
    #create_account .signup-form #feedback {
        margin-top: 1px;
        font-size: 80%;
        color: #dc3545;
        margin-left: 10px;
        width: 100%!important;
        font-weight: 500;
    }
}
@media (min-width: 576px){
    .forgot-modal{
        max-width: 585px!important;
    }
    .message-modal{
        max-width: 585px!important;
    }
}

@media (min-width: 1331px){
    
    #login .login-form .custom-width{
        width:452px!important;
        margin: auto;
    }
    #login #login_actions .custom-width{
        width:452px!important;
        margin: auto;
        padding: 0;
    }
    
}
#login .login-form .custom-width{
    width:auto;
}
#login #login_actions{
    padding: 5px 110px 35px;
}

#pay_now #paynow-form{
    max-width: 650px;
    margin: auto;
    padding: 35px 35px 10px;
    border: 2px solid #cccccc;
    border-radius: 10px;
    margin-top: 35px;
}

#pay_now #paynow-form input{
    border: 2px solid #cccccc;
}
#pay_now #paynow-form .gpa-form-button{
    background-color: #df0018!important;
    border-color: #ef828e!important;
    width: 165px!important;
    padding: 10px;
    float: right;
}

#pay_now #paynow-form .gpa-form-button:hover{
    color: #df0018;
    border-color: #df0018!important;
    background-color: white!important;
}

.modal-body .hint img{
    height: auto;
    width: 100%;
}
#pay_now .progress-line hr{
    border-top: 3px solid rgba(0, 0, 0, 0.1);
    max-width: 320px;
}

#pay_now .progress-balls{
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    top: -10px;
}

#pay_now .progress-balls .step1 {
    display: inline-block;
    margin: 0px 20px!important;
}

#pay_now .progress-balls .step2, #pay_now .progress-balls .step3 {
    display: inline-block;
    margin: 0px 45px;
}
#pay_now .progress-balls .step1 p,#pay_now .progress-balls .step2 p,#pay_now .progress-balls .step3 p {
    margin-top: 10px;
}
#pay_now #gpa-form-actions #cancel{
    padding: 10px 0;
    text-align: left;
}
#pay_now #title{
    margin: 100px 0px 40px;
}
#pay_now #progress{
    margin-bottom: 60px;
}
#pay_now #bottom-text{
    text-align: center;
    margin-right: 220px;
    margin-bottom: 30px;
}
#terms_and_conditions #title, #privacy_policy #title{
    margin: 100px auto 0px;
}
#terms_and_conditions #body, #privacy_policy #body{
    max-width: 850px;
    margin: 45px auto;
}
#terms_and_conditions #body p, #privacy_policy #body p{
    font-weight: 500;
}

#terms_and_conditions #body button, #result_message #body button, #privacy_policy #body button{
    background-color: #df0018;
    border-color: #df0018;
    height: 42px;
    width: 100%;
}
#terms_and_conditions #body button:hover, #result_message #body button:hover, #privacy_policy #body button:hover{
    color: #df0018;
    background-color: white!important;
    border-color: #df0018!important;
}
#result_message {
    margin: 120px 0px 60px;
}
/* //sign-up */
.premise-address-modal .modal-content .modal-header button {
    font-size: 35px;
    font-weight: 400;
    padding: 10px 15px;
}
.modal-dialog.premise-address-modal.modal-dialog-centered {
    max-width: 750px;
}
.modal-dialog.premise-address-modal.modal-dialog-centered .modal-content {
    padding: 20px 15px;
    border-radius: 10px;
}
.modal-dialog.premise-address-modal.modal-dialog-centered .modal-content .modal-header{
    border-bottom: 0px;
}
.modal-dialog.premise-address-modal.modal-dialog-centered .modal-content {
    padding: 20px 15px;
}
.modal-dialog.premise-address-modal.modal-dialog-centered .modal-content .modal-body .address-details-wrap label {
    font-weight: 600;
    font-size: 16px;
}
.modal-dialog.premise-address-modal.modal-dialog-centered .modal-content .modal-body .modal-msg-wrap label {
    font-style: italic;
}
#sign-up-stage{
    margin-top: 25px;
}
#sign-up-stage .border-style{
    border: 1px solid #e5e5e5;
    max-width: 800px;
    margin: auto;
}
#sign-up-stage .border-left-right{
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
}
#sign-up-stage .content {
    padding: 10px 10px 0px;
}
#sign-up-stage .content span{
    margin-right: 10px;
}
#sign-up-stage .selected {
    background-color: #00459e;
    color: #fff;
}
#sign-up-stage .done{
    color: #47c485;
    font-weight: 600;
}
#sign-up-title{
    margin-top: 95px;
}
#sign-up-form {
    margin-top: 50px;
}

#sign-up-form .form-wrap{
    border: 2px solid #cccccc;
    padding: 35px 45px 35px;
    border-radius: 10px;
    max-width: 600px;
    margin: auto;
}
#sign-up-form .form-wrap .form-input{
    border: 2px solid rgb(204, 204, 204);
}
#sign-up-form .form-wrap .form-input-btn-wrap .form-input{
    width: 335px;
    margin-right: 15px;
    border-radius: 5px;
    border: 2px solid rgb(204, 204, 204);
    padding: 5px 10px;
}
#sign-up-form .form-wrap .form-input-btn-wrap .form-button{
    padding: 7px;
    width: 155px;
    background-color: #47c485;
    border-color: #47c485;
}
#sign-up-form .form-wrap .form-input-btn-wrap .form-button:hover{
    background-color: white;
    border-color: #47c485;
    color: #47c485;
}
#sign-up-form .form-wrap .form-link-wrap{
    padding-top: 5px;
}
#sign-up-form-bottom .content-wrap{
    max-width: 600px;
    margin: auto;
}
#sign-up-form-bottom .content-wrap .left label{
    color: #df0018!important;
    font-size: 13px;
    font-weight: 500;
    float: left;
}
#sign-up-form-bottom .content-wrap .right label{
    float: right;
}
#sign-up-form-bottom .content-wrap .right label span:hover{
    text-decoration: underline;
    cursor: pointer
}
#sign-up-form-bottom{
    margin: 25px 0px 40px;
}
#signup-form-buttons{
    margin-top: 15px;
    margin-bottom: 60px;
}
#signup-form-buttons .buttons-wrap{
    width: 600px;
    margin: auto;
    padding: 30px 35px 0px;
}
#signup-form-buttons .left {
    float: left;
}
#signup-form-buttons .right {
    float: right;
}
#signup-form-buttons .right button{
    padding: 7px;
    width: 155px;
    background-color: #47c485;
    border-color: #47c485;
    font-size: 14px;
}

#signup-form-buttons .left .btn-back {
    border: 2px solid rgb(204, 204, 204);
    background-color: white;
    color: #b2b2b2;
    font-size: 13px;
    padding: 8px 30px;
    margin: 0px 5px 0px 0px;
}

#signup-form-buttons .left .btn-cancel {
    border: 0;
    background-color: white;
    color: #b2b2b2;
    font-size: 13px;
    padding: 8px 15px;
}
#signup-form-buttons .right button:hover{
    background-color: white;
    border-color: #47c485;
    color: #47c485;
}
section#success-message-icon {
    margin-top: 110px;
}
section#success-message-title {
    margin-top: 30px;
}
section#success-message-body {
    max-width: 850px;
    margin: 30px auto 0px;
}
section#success-message-button button {
    padding: 7px;
    width: 155px;
    background-color: #47c485;
    border-color: #47c485;
}
section#success-message-button {
    margin-top: 30px;
    margin-bottom: 90px;
}
button .spinner-border-sm {
    margin-bottom: 1px;
    margin-right: 5px;
}
.spinner-border-sm {
    margin-bottom: 3px;
}
.link-hover:hover{
    cursor: pointer;
    text-decoration: underline;
}
@media (max-width: 431px){
    #pay_now .progress-balls .step1, #pay_now .progress-balls .step2, #pay_now .progress-balls .step3 {
        display: inline-block;
        margin: 0px 25px!important;
    }
}
@media (min-width: 992px){
    #terms_and_conditions #body button, #result_message #body button, #privacy_policy #body button {
        background-color: #df0018;
        border-color: #df0018;
        height: 42px;
        width: 25%!important;
    }
}
@media (min-width: 523px) and (max-width: 991px){
    
    #login .login-form .custom-width{
        width:452px;
        margin: auto;
    }
    #login #login_actions .custom-width{
        width:452px;
        margin: auto;
    }
    
}
@media (min-width: 1285px) and (max-width: 1330px){
    
    #login #login_actions .custom-width {
        padding: 0px 110px;
    }
    
}
@media (min-width: 1200px) and (max-width: 1284px){
    
    #login .remember-me {
        padding: 0px 0px 0px;
        margin: 0 auto;
    }    
    #login #login_actions .custom-width{
        width: 100%;
        text-align: center;
    }
}
@media (max-width: 1199px){
    #login #login_actions .custom-width{
        text-align: center;
    }
    #login #login_actions .custom-width .remember-me{
        padding: 0px;
    }
    #login #login_actions .custom-width .forgot{
        text-align: center;
    }
}
@media (min-width: 992px){
    .gpa-form{
        border: 2px solid #cccccc;
        padding: 35px 58px 45px;
        border-radius: 10px;
    }
    .gpa-form-input {
        width: 350px;
        margin-right: 15px;
        border-radius: 5px;
        border: 2px solid #cccccc;
    }
    #create_account .gpa-form-input {
        width: 100%;
        margin-right: 15px;
        border-radius: 5px;
        border: 2px solid #cccccc;
    }
    #create_account .signup-form #gpa-form-actions {
        margin-top: 45px;
    }
}
@media (max-width: 642px){
    #login #login_actions{
        padding: 35px 35px 5px 35px;
        text-align: center;
    }
}
@media (max-width: 991px){
    #login #login_actions{
        padding: 5px 35px 35px 35px;
        text-align: center;
    }
    #footer-main #contacts{
        line-height: 1.3;
        
    }
    #create_account #gpa-form-actions{
        text-align: center;
    }
    #create_account #info-content{
        float: none!important;
    }
    #create_account #sign-up,#create_account #cancel{
        text-align: center;
    }
    #login .login-banner{
        display: none;
    }
    /* for sign up */
    #sign-up-form .form-wrap {
        padding: 25px!important;
    }
    #sign-up-form .form-wrap .form-input-btn-wrap{
        text-align: center!important;
    }
    #sign-up-form .form-wrap .form-input-btn-wrap .form-input {
        width: 100%!important;
        margin-right: 0px!important;
    }
    #sign-up-form .form-wrap .form-input-btn-wrap .form-button {
        margin: 10px 0px!important;
    }
    #sign-up-form .form-wrap .form-link-wrap {
        text-align: center;
    }
    #sign-up-form-bottom {
        text-align: center;
    }
    #sign-up-form-bottom .content-wrap .left label {
        float: none;
    }
    #sign-up-form-bottom .content-wrap .right label {
        float: none;
    }
    #signup-form-buttons .buttons-wrap {
        width: 100%;
        margin: auto;
        padding: 30px 35px 0px;
    }
}
@media (max-width: 1199px){
    
    #create_account #sign-up{
        padding: 0;
    }
    #create_account #sign-up button{
        margin: 0;
    }
    #create_account #cancel {
        padding: 5px 15px;
    }
    .gpa-form{
        width: 100%;
        border: 2px solid #cccccc;
        padding: 35px 35px 45px;
        border-radius: 10px;
    }
    .gpa-form .mobile-view{
        text-align: center;
    }    
    .gpa-form .btn-primary{
        margin-top: 15px;
    } 
    .gpa-form-input {
        width: 100%;
        margin-right: 15px;
        border-radius: 5px;
        border: 2px solid #cccccc;
    }
}
@media (max-width: 991px){
    
    #account_number #quick_links1 .mobile-view,#account_number #quick_links2 .mobile-view{
        margin: auto;
    }
    #title div{
        margin: auto;
    }
    #footer-bottom .footer-bottom-text{
        float: none;
        margin: auto;
        padding: 10px;
        text-align: center;
    }
    #footer-bottom .footer-bottom-text p{
        margin: 0px;
    }
    #footer-bottom .footer-bottom-icons{
        float: none;
        margin: auto;
        margin-bottom: 15px;
        text-align: center;
        padding: 0;
    }
    #pay_now #paynow-form .gpa-form-button{
        float: none;
    }
    #pay_now #paynow-form #proceed{
        text-align: center!important;
    }
    #pay_now #gpa-form-actions #cancel{
        text-align: center!important;
        float: none;
    }
    #login .login-form {
        padding: 10px 35px 5px 35px!important;
    }
}   
@media (max-width: 767px){
    #pay_now #bottom-text{
        margin-right: 85px;
    }
    #pay_now .progress-balls .step1 {
        display: inline-block;
        margin: 0px 10px!important;
    }
    
}
@media (max-width: 532px){
    
}
@media (max-width: 480px){
    #pay_now #progress{
        margin-bottom: 100px!important;
    }
    .progress-line{
        display:none!important
    }
    .show{
        display:block!important
    }
    .hide{
        display:none!important
    }
    #pay_now #bottom-text{
        text-align: center;
        margin: 0;
    }
    /* for sign up */
    #signup-form-buttons {
        margin: 15px auto 0px!important;
        text-align: center!important;
    }
    #signup-form-buttons .left, #signup-form-buttons .right {
        float: none;
    }
    #signup-form-buttons .left{
        margin: 10px 0px;
    }
    #signup-form-buttons .buttons-wrap {
        padding: 0px 35px;
    }
}
@media (max-width: 450px){
    #reset_password #main-form .form-row{
        width: auto;
        margin: auto 20px;   
    }
    #reset_password #main-form .form-row button{
        margin: auto!important;
    }
}
@media (max-width: 370px){
    #login .login-footer p{
        font-size: 3.5vw;
    }
}


/* ALL MEADIA QUERIES HERE! */

/* new shits */

.font-bold-lite {
    font-weight: 500!important;
}

#faq .card-header {
    padding: 15px;
    margin-bottom: 0;
    background-color: white;
    border-bottom: 0px;
}
#faq .card-body {
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0px 30px 15px;
    margin-top: -20px;
}
#faq .accordion > .card:first-of-type {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125)!important;
    border-bottom-right-radius: 0.25rem!important;
    border-bottom-left-radius: 0.25rem!important;
}
#faq .card {
    margin: 10px 0px;
}
#faq .accordion > .card:last-of-type {
    border-top-left-radius: 0.25rem!important;
    border-top-right-radius: 0.25rem!important;
}
#faq button.btn.btn-link {
    font-weight: 500;
    color: black;
}
#faq .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125)!important;
    border-radius: 0.25rem!important;
}
section#faq h5 {
    margin: 15px 0px;
}
section#faq {
    margin-bottom: 40px;
}

#faq button.btn.btn-link:focus, #faq button.btn.btn-link:hover {
    text-decoration: none;
}
#faq #go-back {
    background-color: #08c484;
    border-color: #08c484;
    width: 200px;
    height: 50px;
    margin-top: 25px;
}